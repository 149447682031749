import { createHighlight } from "@/helper/api/ReviewHighlightHelper";


export class VideoTimestamp {
    timestamp = "00:00:00"
    rating = ""
    title = ""
    titleKey = ""
    description = ""
    formData = new FormData();
    image = undefined;
    trainee_id = undefined;
    id = 0

    constructor(videoTimestamp = null) {
        if (videoTimestamp != null) {
            this.timestamp = videoTimestamp.timestamp;
            this.rating = videoTimestamp.rating;
            this.title = videoTimestamp.title;
            this.titleKey = videoTimestamp.titleKey;
            this.description = videoTimestamp.description;
            this.trainee_id = videoTimestamp.trainee_id;
            this.trainee = videoTimestamp.trainee;
            if (videoTimestamp.image !== undefined) {
                this.image = videoTimestamp.image;
            }

            if (videoTimestamp.id !== undefined) {
                this.id = videoTimestamp.id;
            }
        }
    }

    timestampParsed() {
        let hours = this.timestamp.substr(0,2);
        let minutes = this.timestamp.substr(3,2);
        let seconds = this.timestamp.substr(6,2);
        if (parseInt(hours) > 0) {
            minutes = parseInt(minutes) + (parseInt(hours) * 60);
        }
        return minutes + ":" + seconds;
    }

    timestampParsedToSeconds() {
        let hours = parseInt(this.timestamp.substr(0, 2));
        let minutes = parseInt(this.timestamp.substr(3, 2));
        let seconds = parseInt(this.timestamp.substr(6, 2));
        return (hours * 3600) + (minutes * 60) + seconds;
    }

    generateForm() {
        let formData = new FormData();
        formData.append("timestamp", this.timestamp);
        if (this.image !== undefined) {
            formData.append("image", this.image);
        }
        formData.append("rating", this.rating);
        formData.append("title", this.title);
        if(this.description) {
            formData.append("description", this.description);
        }
        if (this.trainee_id) {
            formData.append("trainee_id", this.trainee_id);
        } else {
            formData.append("trainee_id", '');
        }
        return formData;
    }

    addTimestamp(review_id) {
        return createHighlight(review_id, this)
    }
}
