<template>
    <div>
        <div class="transition-opacity duration-300 w-full bg-black_half z-10 absolute" id="review_sub_header_shadow"
             v-bind:class="open ? 'opacity-100 visible h-screen' : 'opacity-0 invisible h-0'">
        </div>

        <div class="w-full bg-scorelitgray pt-10 pb-10 transition-all duration-500 border-t border-b border-gray-600 absolute z-10"
             v-bind:style="open? 'top:'+ headerHeight +'px' : 'top:-600px'" v-if="match"
             v-bind:class="open? 'opacity-100 ' : 'opacity-0'"
        >
            <div class="mx-auto max-w-5xl px-4 lg:px-0">
                <div class="mx-auto">
                    <div class="flex flex-auto">
                        <div class="flex-1 mr-5" v-if="scores.length !== 0">
                            <div class="w-full relative">
                                <img class="mx-auto" src="@/assets/icons/scorelit_emblem.svg">
                                <div class="absolute pb-0 inset-0 flex flex-col items-center justify-center -mt-24">
                                    <h2 class="text-4xl font-bold pb-5 text-scorelitorange">{{ Math.round(average_score) }}</h2>
                                    <h6 class="text-2lg mt-2 font-bold text-white">{{ trainee?.name ?? team?.name }}</h6>
                                </div>
                                <div class="absolute pb-10 inset-0 flex flex-col items-center justify-center mt-56">
                                    <div class="mt-0 mx-auto text-center max-w-xs flex flex-wrap px-20">
                                        <p v-for="score in scores" :key="score.key" class="w-1/2 mb-1 text-xs text-white"><span class="font-bold mr-2">{{ Math.round(score.value) }}</span>{{ score.key }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-2">
                            <div class="flex">
                                <search-property class="w-1/2 inline-block" :type="$t('review.product.name')" :value="review.product.name"></search-property>
                                <search-property class="w-1/2 ml-5 inline-block" :type="$t('review.product.minutes')" :value="review.product.minutes_work"></search-property>
                            </div>
                            <div class="mt-4">
                                <search-property class="w-full h-full  inline-block" :type="$t('review.product.what')" prop="large" :value="review.product.coach_description"></search-property>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchProperty from "@/components/SearchProperty";

function calculateAverage(arr) {
  if(arr.length === 0) {
    return 0;
  }
  let total = 0;

  arr.forEach(element => {
    const value = parseInt(element.value);
    if(!isNaN(value)){
      total += value;
    }
  });

  return total / arr.length
}

export default {
  name: "ReviewSubHeader",
  components: {SearchProperty},
  props: ['match', 'open', 'trainee', 'team', 'review'],
  data() {
    return {
      headerHeight: 0
    }
  },
  mounted() {
    let header = document.getElementById("review-header")
    this.headerHeight = header.clientHeight
  },
  computed: {
    scores () {

      const answers = this.answers.filter(cat => cat.skill !== "n/a");

      return answers.map(cat => {
        return {
          key: cat.skill,
          value: calculateAverage(cat.options)
        }
      })
    },
    average_score () {
      return calculateAverage(this.scores);
    },
    answers () {
      return this.$store.getters.getGeneralSubjectAnswers;
    }
  }
}
</script>

<style scoped>

</style>
