<template>
    <div
        v-if="show"
        class="z-50 h-full absolute w-full flex items-center justify-center font-sans"
        style="left: 0; top: 0"
    >
        <div class="h-full absolute w-full bg-black_half z-50"></div>
        <div class="z-50 modal__container max-w-xl">
            <div class="bg-scorelitgray border border-gray-700 rounded shadow p-8 m-4 w-full max-h-full">
                <div class="mb-4">
                    <template v-if="showInactiveAccountWarning">
                        
                        <div class="rounded-md border border-yellow-800 bg-yellow-900 p-4 mb-10">
                          <div class="flex">
                            <div class="flex-shrink-0">
                              <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                              </svg>
                            </div>
                            <div class="ml-3">
                              <h3 class="text-sm font-medium text-yellow-400">
                                <i18n-t keypath="review.stripe_header" />
                              </h3>
                              <div class="mt-2 text-sm text-yellow-500">
                                <p>
                                  <i18n-t keypath="header.stripe_banner.inactive">
                                    <template #click>
                                      <a @click.prevent="openActivateStripeModal" class="underline cursor-pointer font-semibold">
                                        <i18n-t keypath="header.stripe_banner.inactive_click"/>
                                      </a>
                                    </template>
                                  </i18n-t>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="">
                          <button  class="button w-full mb-4" v-on:click="openActivateStripeModal">
                              <i18n-t keypath="general.activate" />
                          </button>
                          <button class="button-transparent w-full" v-on:click="closeModal">
                                <i18n-t keypath="general.cancel" />
                            </button>
                        </div>

                    </template>
                    <template v-if="!showInactiveAccountWarning">
                        <div class="rounded-md border border-blue-800 bg-blue-900 p-4 mb-10">
                          <div class="flex">
                            <div class="flex-shrink-0">
                              <svg class="h-5 w-5 text-blue-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                              </svg>
                            </div>
                            <div class="ml-3">
                              <h3 class="text-sm font-medium text-blue-100">
                                <i18n-t keypath="review.send.title" />
                              </h3>
                              <div class="mt-2 text-sm text-blue-200">
                                <p>
                                  <i18n-t keypath="review.send.description" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                    
                        <div>
                            <button  class="button w-full mb-4" v-on:click="sendReview">
                                <i18n-t keypath="review.send.submit" />
                            </button>
                            <button class="button-transparent w-full" v-on:click="closeModal">
                                <i18n-t keypath="general.cancel" />
                            </button>
                        </div>
                    </template>
                </div>
                <div class="mb-4">
                    <div></div>
                </div>
            </div>
        </div>
        <ActivateStripeModal v-model:show="state.show_stripe_modal" />
    </div>
</template>

<script>
import { saveReview } from "@/helper/api/ReviewHelper";
import message from "@/helper/store/messageHelper";
import { getStripeAccount } from "@/helper/api/StripeHelper";
import ActivateStripeModal from "@/components/modals/ActivateStripeModal.vue";

export default {
    name: "SendRapportConfirm",
    props: ["show"],
    emits: {
        "update:show": val => typeof val === "boolean"
    },
    created() {
        getStripeAccount().then(account => {
            this.account = account;
        })
    },
    data() {
        return { 
            state: {
                show_stripe_modal: false,
            },
            account: undefined
        }
    },
    computed: {
        user: function(){
            return this.$store.getters['getUser'];
        },
        showInactiveAccountWarning() {
            return !this.user.is_trainer && (!this.hasAccount || this.hasInactiveAccount)
        },
        hasAccount() {
            return this.account !== null
        },
        hasInactiveAccount() {
            return this.account?.capabilities?.transfers !== "active"
        }
    },
    methods: {
        closeModal() {
            this.$emit("update:show", false);
        },
        sendReview() {
            const review_id = this.$route.params.id;
            saveReview(review_id)
                .then(() => {
                    this.$router.push("/dashboard");
                })
                .catch((err) => {
                    if (err?.errors) {
                        this.$emit("update:show", false);
                        for (const key in err.errors) {
                            err.errors[key].forEach(error => {
                                message.error(error);
                            });
                        }
                    } else if (err?.message) {
                        this.$emit("update:show", false);
                        message.error(err.message);
                    } else {
                        throw err;
                    }
                });
        },
        openActivateStripeModal() {
            this.state.show_stripe_modal = true;
        },
    },
    components: {
        ActivateStripeModal
    }
};
</script>

<style scoped>
</style>
