import axios from 'axios';
import store from '@/store';

const save_video_path = "v1/coach/review/video/"; // + review_id
const update_video_path = "v1/coach/review/video/"; // + video_id // patch

const save_demo_video_path = "v1/coach/demo/video/";
const update_demo_video_path = "v1/coach/demo/video/"; // patch

function saveVideo(review_id, data)
{
    return new Promise((resolve, reject) =>{
        let url;
        if (review_id == 'demo') {
            url = save_demo_video_path;
        } else {
            url = save_video_path + review_id;
        }

        const formData = new FormData();
        formData.append("video", data.video);
        formData.append("title", data.title);
        formData.append("description", data.description);

        const config = {
            headers: {'Content-Type': 'multipart/form-data'},
        };

        axios.post(url, formData, config)
            .then(response => {
                store.commit("setVideo", response.data.video);
                resolve(response.data.video);
            })
            .catch(err => {
                reject(err.response.data);
            })
    });
}

function updateVideo(review_id, video_id, data)
{
    return new Promise((resolve, reject) =>{
        let url;
        if (review_id == 'demo') {
            url = update_demo_video_path + '?_method=patch';
        } else {
            url = update_video_path + video_id + '?_method=patch';
        }
        
        const formData = new FormData();
        formData.append("video", data.video);
        formData.append("title", data.title);
        formData.append("description", data.description);

        const config = {
            headers: {'Content-Type': 'multipart/form-data'},
        };

        axios.post(url, formData, config)
            .then(response => {
                store.commit("setVideo", response.data.video);
                resolve(response.data.video);
            })
            .catch(err => {
                reject(err.response.data);
            })
    });
}

function saveOrUpdateVideo(review_id, video_id, data)
{
    if (video_id){
        return updateVideo(review_id, video_id, data);
    } else {
        return saveVideo(review_id, data);
    }
}

export { saveVideo, updateVideo, saveOrUpdateVideo };
