import axios from 'axios';
import store from '@/store';

const index_review_path = "v1/coach/review"; 
const get_review_path = "v1/coach/review/"; // + review_id // get
const save_review_path = "v1/coach/review/"; // + review_id
const update_review_path = "v1/coach/review/"; // + review_id // patch


function prepareDemo(demo)
{
    if(!demo){
        return ;
    }
    demo.id = "demo";

    return demo;
}

function indexReview()
{
    return new Promise((resolve, reject) =>{
        axios.get(index_review_path)
            .then((response)=>{
                const demo = prepareDemo(response.data.demo);

                if(demo) {
                    resolve([...response.data.reviews, demo]);
                } else {
                    resolve(response.data.reviews);
                }
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function getReview(review_id)
{
    const url = get_review_path + review_id;

    return axios.get(url)
        .then((response)=>{
            if ('demo' in response.data){
                response.data.review = prepareDemo(response.data.demo)
            }

            store.commit("setVideo", response.data.review.video);
            store.commit("setSubjects", response.data.review.questions);

            return response.data.review;
        })
        .catch(err => {
            if (!err.response) {
                throw err
            }
            throw {...err.response.data, status: err.response.status};
        });
}

function saveReview(review_id)
{
        const url = save_review_path + review_id;

        const formData = new FormData();

        if(store.state.rapport.subjects) {
            formData.append("questions", JSON.stringify(store.state.rapport.subjects));
        }

        const config = {
            headers: {'Content-Type': 'multipart/form-data'},
        };

        return axios.post(url, formData, config)
            .then(response => response.data)
            .catch(err => {
                throw err.response?.data ?? err;
            })
    
}

function updateReview(review_id)
{
    return new Promise((resolve, reject) =>{
        const url = update_review_path + review_id + '?_method=patch';

        const formData = new FormData();
        formData.append("questions", JSON.stringify(store.state.rapport.subjects));

        const config = {
            headers: {'Content-Type': 'multipart/form-data'},
        };

        axios.post(url, formData, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err.response.data);
            })
    });
}

export { indexReview, getReview, saveReview, updateReview };