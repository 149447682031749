function status (review){
    if (review.reviewed_at !== null){
        return 'finished';
    } else if (review.created_at !== review.updated_at){
        return 'in_progress'
    } else {
        return 'new';
    }
}

export { status };