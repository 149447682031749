<template>
  <div>
    <div class="mt-5">
      <p class="inline-block text-xs font-medium w-4/5 unselectable">{{ name }}</p>
      <p v-if="newValue == 0" class="inline-block float-right font-bold unselectable">
        <i18n-t keypath="review.noValue" />
      </p>
      <p v-else-if="newValue == null" class="inline-block float-right font-bold unselectable"></p>
      <p v-else class="inline-block float-right font-bold unselectable">{{ newValue }}</p>
      <label>
        <input type="range" min="0" max="100" class="slider w-full float-left relative cursor-pointer h-6 unselectable" v-model="newValue" v-on:change="updateValue">
      </label>
      <div 
        class="bar mt-1"
        v-bind:class="{
            'bar-undone': newValue == null,
            'bar-done': newValue != null,
            }">
        <span class="bar-transition" :style="'width: ' + newValue + '%'"
              v-bind:class="{
                'background-nvt': newValue == 0,
                'background-red': newValue <= 33 && newValue >= 1,
                'background-orange': (newValue > 33 && newValue <= 66),
                'background-green': newValue > 66,
              }">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import messages from "@/helper/store/messageHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
  name: "QuestionSlide",
  props: ["name", "value", "minValue"],
  data() {
    return {
      newValue: this.value
    }
  },
  methods: {
    updateValue() {
      if (this.newValue !== 0 && this.newValue < this.minValue){
        messages.warn($t('review.errors.minimum', {value: this.minValue}))
        this.newValue = this.minValue
      }

      this.$store.commit("updateSubjectQuestion", {
        name: this.name,
        value: this.newValue,
        type: "slide"
      })
    }
  }
}
</script>

<style scoped>

.slider {
    top: 0px; 
    z-index: 10; 
    opacity: 0
}

.slider::-webkit-slider-thumb {
  width: 0;
  height: 0;
  opacity: 0;
}

.slider::-moz-range-thumb {
  width: 0;
  height: 0;
  opacity: 0;

}

.bar {
  height: 14px;
  position: relative;
  border-radius: 25px;
}

.bar-done {
  background-color: rgb(121, 121, 121);
}

.bar-undone {
  background: #555;
}

.bar-transition {
  transition: background-color 0.2s ease-in-out;
}

.bar > span {
  display: block;
  height: 14px;
  border-radius: 25px;
  position: absolute;
  left: 0;
  top: 0;
}

.background-nvt {
  background-color: rgb(121, 121, 121);
  width: 100%;
}

.background-red {
  background-color: #A62130;
}

.background-orange {
  background-color: #f56d2c;
}

.background-green {
  background-color: #5CB824;
}
</style>
