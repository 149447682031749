<template>
  <div class="mt-4">
    <label for="conclusion" class="font-bold text-sm">
      <i18n-t keypath="review.conclusion.title" />
    </label>
    <textarea id="conclusion" :placeholder="$t('review.conclusion.placeholder')" class="p-4 mt-4 bg-transparent border border-gray-200 rounded text-sm w-full" v-model="newValue" v-on:change="updateConclusion"></textarea>

  </div>
</template>

<script>
export default {
  name: "ConclusionRapport",
  props: ['subject', 'value'],
  data() {
    return {
      newValue: this.value,
    }
  },
  methods: {
    updateConclusion() {
      this.$store.commit("updateSubjectConclusion", {
        value: this.newValue,
        subject: this.subject
      });
    }
  }
}
</script>

<style scoped>

</style>
