<template>
  <div class="mb-4">
    <div class="flex items-center justify-between px-4 py-4 bg-scorelitgray rounded-t text-white">
      <div class="font-medium inline-block text-white bg-gradient-to-r from-gradient-orange text-sm px-3 py-2 to-gradient-red rounded unselectable">
        {{videoTimestamp.timestampParsed()}}
      </div>
      <div>
        <h2 class="w-full text-base font-medium" v-if="highlight!==undefined">
          <i18n-t keypath="review.highlights.edit" />
        </h2>
        <h2 class="w-full text-base font-medium" v-else>
          <i18n-t keypath="review.highlights.new" />
        </h2>
      </div>
      <div class="w-10">
        <button v-if="highlight!==undefined" class="flex items-center float-right bg-black text-white rounded-lg px-3 py-3 text-black inline-block text-xs font-medium transition duration-200 hover:bg-red-600 focus:outline-none active:outline-none" v-on:click="deleteHighlight">
            <i class="fa fa-trash-alt rounded-full transition duration-300 ease-in-out text-xs"></i>
        </button>
      </div>
    </div>
    <div class="bg-scorelitgray min-h-full rounded-b">
      <div class="py-4 px-4">
        <div class="mt-0">
          <i class="fal fa-thumbs-up rounded-full p-3 mr-3 transition duration-300 ease-in-out cursor-pointer"
             v-bind:class="form.rating === '+'? 'bg-button-green text-white': 'bg-white text-black '"
             v-on:click="form.rating = '+'"></i>
          <i class="fal fa-thumbs-down rounded-full p-3 transition duration-300 ease-in-out cursor-pointer"
             v-bind:class="form.rating === '-'? 'bg-button-red text-white': 'bg-white text-black'"
             v-on:click="form.rating = '-'"></i>
        </div>

        <div class="mt-6" v-if="traineeOptions.length">
          <h2 class="text-sm font-medium mb-4">
            <i18n-t keypath="review.highlights.trainee" />
          </h2>
          <SelectInput
              v-model:value="form.trainee_id"
              :placeholder="false"
              :options="traineeOptions"
              theme="dark"
          />
        </div>

        <div class="mt-6">
          <h2 class="text-sm font-medium">
            <i18n-t keypath="review.highlights.type" />
          </h2>

          <button 
            v-for="(subject, index) in subjects" :key="index"
            class="rounded-lg py-2 px-4 font-medium text-xs mt-4 mr-4 unselectable focus:outline-none active:outline-none"
            v-bind:class="form.title === subject.value ? choosePillColor(subject.value) : 'bg-black text-gray-100'"
            v-on:click="form.title = subject.value"
          >
            <i class="w-2 h-2 rounded-full inline-block mr-3 transition duration-300 ease-in-out"
              v-bind:class="form.title === subject.value ? 'bg-black' : choosePillColor(subject.value)"></i>
              {{ subject.label }}
          </button>
          
        </div>
        <div class="mt-6">
          <h2 class="text-sm font-medium">
            <i18n-t keypath="review.highlights.description.title" />
          </h2>
          <label>
            <textarea 
              v-model="form.description" 
              :placeholder="$t('review.highlights.description.placeholder')" 
              rows="5" 
              class="p-4 mt-4 bg-transparent border border-gray-200 rounded text-sm w-full" 
            />              
            <span v-if="requiredDescription" class="text-xs text-red-500 relative" style="left: 20px;">
              <i18n-t keypath="review.highlights.description.required" />
            </span>
          </label>
        </div>
        <div class="mt-6 mb-2">
          <button class="button w-full" v-on:click="EditHighlight" v-if="highlight !== undefined">
            <i18n-t keypath="review.highlights.saveEdit" />
          </button>
          <button class="button w-full" v-on:click="submitHighlight" v-else>
            <i18n-t keypath="review.highlights.saveNew" />
          </button>
          <button class="button-transparent p-4 mt-4 w-full rounded text-sm" v-on:click="closeTimestamp">
            <i18n-t keypath="general.cancel" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VideoTimestamp} from "@/classes/VideoTimestamp";
import { updateHighlight, deleteHighlight } from "@/helper/api/ReviewHighlightHelper";
import {setLoading} from "@/helper/store/loadingSpinnerHelper";
import messages from "@/helper/store/messageHelper";
import SelectInput from '@/components/forms/SelectInput.vue';
import { choosePillColor } from "@/helper/video/ColorHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
  name: "NewHighlight",
  props: ["timestamp", 'subjects', 'team'],
  data: function () {
    return {
      form: {
        timestamp: this.$store.state.highlight.timestamp,
        rating: "+",
        title: this.subjects[0].value,
        description: undefined,
        image: undefined,
        trainee_id: null,
      },
      videoTimestamp: null,
      requiredDescription: false,
      highlight: undefined
    }
  },
  created() {
    this.videoTimestamp = new VideoTimestamp(this.form);

    this.highlight = this.$store.state.highlight.editHighlight;
    if (this.highlight !== undefined) {
      this.form.timestamp = this.highlight.timestamp;
      this.form.rating = this.highlight.rating;
      this.form.title = this.highlight.titleKey || this.highlight.title;
      this.form.description = this.highlight?.description;
      this.form.trainee_id = this.highlight?.trainee?.id;
    }
  },
  mounted() {
    this.videoTimestamp.timestamp = this.$store.state.highlight.timestamp;
  },
  computed: {
    traineeOptions() {
      if (!this.team) {
        return []
      }

      const trainees = this.team.trainees.map(trainee => ({
        key: trainee.id,
        text: `${trainee.first_name} ${trainee.last_name}`
      }))

      trainees.unshift({key: null, text: $t("review.highlights.traineeAll")})
      return trainees
    }
  },
  methods: {
    deleteHighlight() {
      const review_id = this.$route.params.id;
      const highlight_id = this.highlight.id;
      deleteHighlight(review_id, highlight_id)
        .then(() => {
          messages.success($t('review.highlights.deleteSuccess'));
          this.closeTimestamp();
        })
        .catch(err => console.log(err))
    },
    closeTimestamp() {
      this.$emit("close");
    },
    EditHighlight() {
      this.form.image = this.$store.state.highlight.editHighlight.image;
      this.form.timestamp = this.$store.state.highlight.editHighlight.timestamp + '.000';

      setLoading(true);
      const review_id = this.$route.params.id;
      const highlight_id = this.highlight.id;
      updateHighlight(review_id, highlight_id, this.form)
        .then(() => {
          messages.success($t('review.highlights.editSuccess'));
          this.closeTimestamp();
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    },

    dataURIToBlob(dataURI) {
      let splitDataURI = dataURI.split(',')
      let byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      let mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      let ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], { type: mimeString })
    },
    submitHighlight() {
      this.requiredDescription = false;
      const description = this.form.description;
      if (!description) {
        this.requiredDescription = true;
      } else {
        if (this.$store.state.highlight.drawing !== undefined) {
          this.form.image = this.dataURIToBlob(this.$store.state.highlight.drawing);
          this.$store.commit("setDrawing", undefined);
        }
        let videoTimestamp = new VideoTimestamp(this.form);
        this.$emit("timestamp", videoTimestamp.addTimestamp(this.$route.params.id));
      }
    },
    choosePillColor
  },
  components: {
    SelectInput
  }
}
</script>

<style scoped>

</style>
