<template>
    <div class="mb-4">
        <div class="flex items-center">
            <button class="font-medium inline-block text-white bg-gradient-to-r from-gradient-orange text-xs px-2 to-gradient-red rounded rounded-bl-none" style="height: 34px" v-on:click="setTimestampHighlight">{{ videoTimestamp.timestampParsed()}}</button>
            <div class="ml-3 flex items-center float-right bg-button-green text-white rounded-lg px-3 py-2 inline-block text-xs font-medium transition duration-300 ease-in-out" v-bind:class="videoTimestamp.rating === '+'? 'bg-button-green': 'bg-button-red'" style="height: 34px">
                <i class="fa fa-thumbs-up rounded-full transition duration-300 ease-in-out text-xs" v-if="videoTimestamp.rating === '+'"></i>
                <i class="fa fa-thumbs-down rounded-full transition duration-300 ease-in-out text-xs" v-else></i>
            </div>
            <button v-if="videoTimestamp.trainee" class="mx-3 bg-blue-900 text-gray-200 rounded-lg px-3 py-2 inline-block text-xs font-medium cursor-default">
                {{ videoTimestamp.trainee.name }}
            </button>
            <button v-else class="mx-3 bg-black text-gray-200 rounded-lg px-3 py-2 inline-block text-xs font-medium cursor-default">
                <i class="w-2 h-2 rounded-full inline-block mr-3 transition duration-200 ease-in-out" v-bind:class="choosePillColor(videoTimestamp.title)"></i>{{ stripHashtags(videoTimestamp.title) }}
            </button>
            <button class="flex items-center ml-auto float-right bg-black text-white rounded-lg px-3 py-3 inline-block text-xs font-medium transition duration-200 hover:bg-gray-900 focus:outline-none active:outline-none" v-on:click="openHighlight">
                <i class="fa fa-edit rounded-full transition duration-300 ease-in-out text-xs"></i>
            </button>
        </div>
        <p class="bg-black rounded-md mt-2 p-4 text-sm text-gray-300 cursor-pointer hover:text-white hover:bg-gray-800 transition duration-300" v-on:click="setTimestampHighlight">{{ videoTimestamp.description }}</p>
    </div>
</template>

<script>
import { choosePillColor, stripHashtags } from "@/helper/video/ColorHelper";

export default {
  name: "Highlight",
  props: ['videoTimestamp', 'reviewID'],
  created() {
  },
  methods: {
    choosePillColor,
    stripHashtags,
    setTimestampHighlight() {
      this.$store.commit("setHighlightShow", this.videoTimestamp);
      this.$store.commit("setTimestampHighlight", this.videoTimestamp.timestampParsedToSeconds());
    },
    openHighlight() {
      this.$store.commit("setHighlightShow", {});
      this.$store.commit("setEditHighlight", this.videoTimestamp);
    },
  }
}
</script>

<style scoped>

</style>
