<template>
  <div v-if="show" class="z-50 h-full absolute w-full flex items-center justify-center  font-sans" style="left: 0; top: 0" >
    <div v-on:click="closeRecording" class="h-full absolute w-full bg-black_half z-50"></div>
    <div class="items-center justify-center z-50 modal__container">
      <div class="bg-scorelitgray border border-gray-700 rounded shadow p-4 m-4 w-full max-h-full text-center">
        <div class="mb-4">
          <h1 class="text-white text-lg font-semibold" v-if="!showOnlyVideo">
            <i18n-t keypath="review.message.record.title" />
          </h1>
          <h1 class="text-white text-lg font-semibold" v-else>
            <i18n-t keypath="review.message.title" />
          </h1>
        </div>
        <div class="mb-4">
          <div>
              <video ref="userWebVideo" autoplay class="w-full">
              </video>
          </div>
          <div v-if="webCamAvailable && !showOnlyVideo" class="mt-8">
            <button class="bg-button-green transition duration-200 hover:bg-green-600 rounded px-3 py-3 md:px-3 md:py-3 lg:px-6 lg:py-4 text-white text-sm font-medium" v-if="!record" v-on:click="startRecording">
              <i18n-t keypath="review.message.record.start" />
            </button>
            <button class="bg-button-green transition duration-200 hover:bg-green-600 rounded px-3 py-3 md:px-3 md:py-3 lg:px-6 lg:py-4 text-white text-sm font-medium" v-else v-on:click="stopRecording">
              <i18n-t keypath="review.message.record.stop" />
            </button>
          </div>
          <div v-if="!webCamAvailable && !showOnlyVideo" class="mt-8">
            <button class="bg-button-green transition duration-200 hover:bg-green-600 rounded px-3 py-3 md:px-3 md:py-3 lg:px-6 lg:py-4 text-white text-sm font-medium" v-on:click="startWebcam">
              <i18n-t keypath="review.message.record.replace" />
            </button>
          </div>
          <div v-if="showOnlyVideo" class="mt-8">
            <button class="button-transparent" v-on:click="closeRecording">
              <i18n-t keypath="general.close" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setLoading} from "@/helper/store/loadingSpinnerHelper";
import { saveOrUpdateVideo } from "@/helper/api/ReviewVideoHelper";
import messages from "@/helper/store/messageHelper";
import ysFixWebmDuration from "fix-webm-duration";

import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
  name: "VideoRecording",
  data() {
    return {
      video: this.$store.state.rapport.video,
      constrains: {
        video: true,
        audio: true
      },
      webCamAvailable: false,
      record: false,
      frames: [],
      recId: undefined,
      tracks: undefined,
      show: false,
      recorder: undefined,
      showOnlyVideo: this.$store.state.rapport.showOnlyVideo,
      videoTimings: {
        start: undefined,
      },
      mediaParts: [],
    }
  },
  emits: {
    "modal-closed": () => true,
  },
  watch: {
    '$store.state.rapport.videoShow': function () {
      this.showOnlyVideo = this.$store.state.rapport.showOnlyVideo
      this.show = this.$store.state.rapport.videoShow;
      if(this.show) {
        if (this.video !== undefined && this.video !== null && this.showOnlyVideo) {
          setTimeout(() => {
            this.$refs.userWebVideo.muted = false;
            this.$refs.userWebVideo.src = this.video.path;
            this.$refs.userWebVideo.controls = true;
          }, 100);
        } else {
          this.startWebcam();
        }
      }
    },
    '$store.state.rapport.video': function () {
      this.video = this.$store.state.rapport.video;
    },
  },
  methods: {
    startWebcam() {
      navigator.mediaDevices.getUserMedia(this.constrains).then((stream) => {
        this.$refs.userWebVideo.muted = true;
        this.$refs.userWebVideo.controls = false;
        this.$refs.userWebVideo.srcObject = stream
        this.tracks = this.$refs.userWebVideo.srcObject.getTracks();
        this.webCamAvailable = true;

        const mediaRecorderTypes = [
          'video/mp4;codecs:h264',
          'video/webm;codecs=vp9',
          'video/webm;codecs:vp9',
          'video/webm'
        ]

        let i = 0;
        while (!this.mediaRecorder && i < mediaRecorderTypes.length) {
          if (MediaRecorder.isTypeSupported(mediaRecorderTypes[i]))
          {
            this.mediaRecorder = new MediaRecorder(stream, { mimeType : mediaRecorderTypes[i] });
          } 
          i++;
        }

        if (!this.mediaRecorder) {
          throw new Error("Could not find supported media recorder type");
        }


        this.mediaRecorder.onstop = this.handleMediaRecorderStop;


        this.mediaRecorder.ondataavailable = ({ data }) => {
          console.log("ondataavailable", data)
          if (data && data.size > 0) {
              this.mediaParts.push(data);
          }
        };
      });
    },
    stopWebcam() {
      this.webCamAvailable = false;

      this.tracks?.forEach((track) => {
        track.stop();
      });
    },
    closeRecording() {
      this.stopWebcam();
      this.closeModal();
    },
    saveImageFrame() {
      let ctx = this.$refs.userWebCanvas.getContext("2d");
      ctx.drawImage(this.$refs.userWebVideo, 0, 0, this.$refs.userWebVideo.clientWidth, this.$refs.userWebVideo.clientHeight);
      let dataUrl = this.$refs.userWebCanvas.toDataURL();
      this.frames.push(dataUrl);
      if (this.record) {
        requestAnimationFrame(this.saveImageFrame);
      }
    },
    stopRecording() {
      this.record = false;

      cancelAnimationFrame(this.recId);
      this.mediaRecorder.stop();
      this.stopWebcam();
    },
    startRecording() {
      this.record = true;
      
      this.mediaRecorder.start();
      this.videoTimings.start = Date.now();
    },
    async handleMediaRecorderStop() {
      const duration = Date.now() - this.videoTimings.start;

      const blobType = this.mediaRecorder.mimeType.startsWith("video/mp4")
        ? "video/mp4"
        : "video/webm"

      let blob;
      if (blobType === "video/webm") {
        const brokenBlob = new Blob(this.mediaParts, { type: blobType });  
        blob = await ysFixWebmDuration(brokenBlob, duration, { logger: false })
      } else {
        blob = new Blob(this.mediaParts, { type: blobType });
      }
      
      // clear for next use
      this.mediaParts = [];

      this.$refs.userWebVideo.controls = true;

      const data = {
        video: blob,
        title: 'video-message',
        description: 'scorelit-expert',
      };
      const review_id = this.$route.params.id;
      const video_id = this.video? this.video.id: undefined;
      setLoading(true);

      await saveOrUpdateVideo(review_id, video_id, data)
        .then(() => {
          messages.success($t('review.message.success'));
        })
        .catch(err => {
          messages.error(err.message);
          console.log(err);
        })
        .finally(()=> {
          setLoading(false);
          this.closeModal()
        });
    },
    closeModal() {
      // HACK: emit to parent to hard refresh this component
      this.$emit("modal-closed");
      this.$store.commit("setVideoShow", false);
    }
  }
}
</script>

<style scoped>
.modal__container {
  width: 40%;
  height: 80%;
}
</style>
