<template>
    <Modal :isOpen="show">
      <h1 class="text-black text-lg font-semibold mb-2">Stripe account koppelen</h1>
      <p class="text-sm mb-4">Alle uitbetalingen van Scorelit worden afgewikkeld door onze betaalprovider Stripe. Om uitbetalingen in te stellen, dien je ervoor te zorgen dat je Stripe account aanmaakt, een bankrekeningnummer koppelt en ze zullen je identiteit moeten verifiëren.</p>
        <DropdownInput 
            v-model:value="form.country" 
            v-model:errors="errors.country" 
            :options="countryOptions" 
            label="In welk land woon je?" 
        />
        <div class="flex flex-col justify-between items-center pt-6 space-y-2">
          <button class="button w-full" @click.prevent="handeSubmit">Stripe account aanmaken</button>
          <button class="button-white w-full" @click.prevent="closeModal">Sluiten</button>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/wrappers/partials/Modal.vue";
import { getCountries } from "@/helper/api/CountryHelper";
import DropdownInput from "@/components/forms/SelectInput.vue";
import { createStripeAccount } from "@/helper/api/StripeHelper";
import message from "@/helper/store/messageHelper";
import {setLoading} from "@/helper/store/loadingSpinnerHelper";

export default {
    components: {
        DropdownInput,
        Modal,
    },
    props: {
        show: Boolean,
    },
    created() {
        getCountries().then(countries => {
            this.countryOptions = countries.map(country => {
                return {
                    key: country.iso_2,
                    text: country.name,
                }
            });
        })
    },
    data(){ 
        return {
            form: {
                country: ""
            },
            errors: {
                country: []
            },
            countryOptions: [],
        }
    },
    methods:{ 
        handeSubmit() {
            setLoading(true);
            createStripeAccount(this.form)
                .then((data) => {
                    message.success("Account gemaakt");
                    window.location.href = data.account_link.url;
                })
                .catch(e => {
                    if (e.response?.data?.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        message.error(e.response?.data?.message ?? e.message);
                        throw e;
                    }
                })
                .finally(() => setLoading(false))
        },
        closeModal () {
            this.$emit('update:show', false);
        }
    },
    emits: {
        "update:show": (show) => typeof show === "boolean",
    }
}
</script>