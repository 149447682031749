<template>
  <div class="z-20 relative bg-scorelitgray" id="review-header">
    <div class="relative z-10 shadow flex">
      <div class="flex flex-auto px-4 md:px-8 justify-items-center items-center h-20">
        <div class="flex-1 cursor-pointer group pr-4">
          <router-link to="/dashboard">
            <i class="fal fa-angle-left bg-white text-black rounded-full p-2 pl-3 pr-3 group-hover:bg-gray-300 transition duration-300"></i>
            <p class="hidden lg:inline-block text-white inline-block ml-4 group-hover:text-gray-300 transition duration-300">
              <i18n-t keypath="general.back" />
            </p>
          </router-link>
        </div>
        <div class="flex justify-center h-full">
          <div v-if="showSubHeaders" class="border-l border-r border-gray-600 flex items-center px-4 md:px-8 cursor-pointer  hover:bg-gray-800 transition duration-200" v-on:click="openTraineeSubHeader = !openTraineeSubHeader">
            <i class="fal fa-user-alt transition duration-600" v-bind:class="openTraineeSubHeader ? 'text-gradient' : 'text-white'"></i>
          </div>
          <div class="flex items-center px-4 md:px-8">
            <h1 class="hidden md:inline-block text-xs sm:text-sm text-white font-medium relative cursor-default truncate ...">
              {{ title }}
            </h1>
          </div>
          <div v-if="showSubHeaders" class="border-l border-r border-gray-600 flex items-center px-4 md:px-8 cursor-pointer hover:bg-gray-800 transition duration-200" v-on:click="openReviewSubHeader = !openReviewSubHeader">
            <i class="fal fa-shield transition duration-600" v-bind:class="openReviewSubHeader ? 'text-gradient' : 'text-white'"></i>
          </div>
        </div>
        <div class="flex-1" >
          <template v-if="showSaveButton">
            <button class="hidden md:block button float-right relative my-4" v-on:click="saveRapport">
              <i18n-t keypath="review.save" />
            </button>
            <button class="block md:hidden text-white font-base float-right relative my-4" v-on:click="saveRapport"><i class="fal fa-save text-gradient"></i></button>
          </template>
        </div>
      </div>
    </div>

    <trainee-sub-header 
      v-if="showSubHeaders && trainee"
      :match="match" 
      :open="openTraineeSubHeader" 
      :trainee="trainee" 
      :review="review"
    ></trainee-sub-header>

    <review-sub-header 
      v-if="showSubHeaders"
      :match="match" 
      :open="openReviewSubHeader" 
      :trainee="trainee" 
      :team="team" 
      :review="review"
      ></review-sub-header>
  </div>
</template>

<script>
import TraineeSubHeader from "@/components/review/TraineeSubHeader";
import ReviewSubHeader from "@/components/review/ReviewSubHeader";
import { updateReview } from "@/helper/api/ReviewHelper";
import messages from "@/helper/store/messageHelper";
import { status } from "@/helper/models/Review";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
  name: "Header",
  components: {
    TraineeSubHeader, 
    ReviewSubHeader
  },
  props: ["title", 'match', 'trainee', 'team', 'review'],
  data() {
    return {
      openTraineeSubHeader: false,
      openReviewSubHeader: false
    }
  },
  computed: {
    raportSubmitted() {
      if(!this.review) {
        return false
      }
      return status(this.review) === "finished";
    },
    showSaveButton() {
      return !this.raportSubmitted
    },
    showSubHeaders() {
      return !this.team
    }
  },
  mounted() {
    document.getElementById("trainee_sub_header_shadow")?.addEventListener('click', () => {
      if (this.openTraineeSubHeader) {
        this.openTraineeSubHeader = false;
      }
    });
    document.getElementById("review_sub_header_shadow").addEventListener('click', () => {
      if (this.openReviewSubHeader) {
        this.openReviewSubHeader = false;
      }
    });
  },
  methods: {
    saveRapport() {
      const review_id = this.$route.params.id;
      updateReview(review_id)
        .then(() => {
          messages.success($t('review.saved'));
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.fa-shield, .fa-user-alt, .fa-save {
  font-size: 1.6rem;
}
</style>
