import axios from 'axios';
import store from '@/store';
import {VideoTimestamp} from "@/classes/VideoTimestamp";

const create_highlight_path = "v1/coach/review/timestamp/{review_id}"
const update_highlight_path = "v1/coach/review/timestamp/{highlight_id}"; // patch
const delete_highlight_path = "v1/coach/review/timestamp/{highlight_id}";  // delete

const create_demo_highlight_path = "v1/coach/demo/timestamp/"
const update_demo_highlight_path = "v1/coach/demo/timestamp/{highlight_id}"; // patch
const delete_demo_highlight_path = "v1/coach/demo/timestamp/{highlight_id}";  // delete

function createHighlight(review_id, data)
{
    return new Promise((resolve, reject) =>{
        let url;
        if (review_id == "demo") {
            url = create_demo_highlight_path
        } else {
            url = create_highlight_path.replace('{review_id}', review_id);
        }
        
        const videoTimestamp = data instanceof VideoTimestamp
            ? data
            : new VideoTimestamp(data);
        const formData = videoTimestamp.generateForm();

        const config = {
            headers: {'Content-Type': 'multipart/form-data'},
        };

        axios.post(url, formData, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err.response.data);
            })
    });
}

function updateHighlight(review_id, highlight_id, data)
{
    return new Promise((resolve, reject) =>{
        let url;
        if (review_id == "demo") {
            url = update_demo_highlight_path.replace('{highlight_id}', highlight_id) + '?_method=patch';
        } else {
            url = update_highlight_path.replace('{highlight_id}', highlight_id) + '?_method=patch';
        }
        
        const videoTimestamp = new VideoTimestamp(data);
        const formData = videoTimestamp.generateForm();
        formData.delete("image");

        const config = {
            headers: {'Content-Type': 'multipart/form-data'},
        };

        axios.post(url, formData, config)
            .then(response => {
                store.commit("setVideo", response.data.video);
                resolve(response.data.video);
            })
            .catch(err => {
                reject(err.response.data);
            })
    });
}

function deleteHighlight(review_id, highlight_id)
{
    return new Promise((resolve, reject) =>{
        let url;
        if (review_id == "demo") {
            url = delete_demo_highlight_path.replace('{highlight_id}', highlight_id) + '?_method=delete';
        } else {
            url = delete_highlight_path.replace('{highlight_id}', highlight_id) + '?_method=delete';
        }

        axios.post(url, null)
            .then(() => {
                resolve();
            })
            .catch(err => {
                reject(err.response.data);
            })
    });
}
export { createHighlight, updateHighlight, deleteHighlight };
