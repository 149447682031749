<template>
  <div class="mt-5">
      <div class="flex items-center">
          <input type="checkbox" class="checkbox mr-3 transition duration-150 ease-in-out cursor-pointer" :checked="newValue" v-on:change="updateValue()" :id="name.replace(/\s/g, '')" v-model="newValue">
          <label class="text-sm cursor-pointer" :for="name.replace(/\s/g, '')">{{ name }}</label>
      </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxRapport",
  props: ["name", "value"],
  data() {
    return {
      newValue: this.value
    }
  },
  methods: {
    updateValue() {
      this.$store.commit("updateSubjectQuestion", {
        name: this.name,
        value: this.newValue,
        type: "checkbox"
      })
    }
  }
}
</script>

<style scoped>

input[type="checkbox"] {
    height: 1.5rem;
    width: 1.5rem;
    border: 0px;
}

input[type="checkbox"]:checked {
    background-color: #2DB653;
}

</style>
