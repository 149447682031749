<template>
  <div>
    <label
      v-if="label"
      :for="label"
      class="block text-xs sm:text-sm font-medium leading-5"
      :class="theme === 'dark' ? 'text-white' : 'text-gray-900'"
      >{{ label }}</label
    >
    <div class="mt-1 rounded-md shadow-sm text-xs sm:text-sm border">
      <select
        ref="input"
        name=""
        id="label"
        @change="updateValue()"
        class="block form-select w-full transition duration-150 ease-in-out text-xs sm:text-sm sm:leading-5 "
        :class="[
          { 'border-gray-400 border': border === 'enable' },
          { 'bg-scorelitgray text-gray-300': theme === 'dark' },
          { 'text-gray-900': theme !== 'dark' },
        ]"
      >
        <option value="" disabled :selected="placeholder !== false && !value">
          <i18n-t keypath="inputs.select.blank" />
        </option>
        <option
          v-for="option in options"
          :selected="option.key == value"
          :key="option.key"
          :value="option.key"
          class="text-xs sm:text-sm"
          >{{ option.text }}</option
        >
      </select>

      <div
        v-if="hasErrors"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <template v-if="hasErrors">
      <p
        v-for="error in errors"
        :key="error"
        class="mt-2 text-sm text-red-600 font-medium"
      >
        {{ error }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  props: [
    "value",
    "errors",
    "label",
    "placeholder",
    "options",
    "theme",
    "border",
  ],
  computed: {
    hasErrors: function() {
      return this.errors && this.errors.length !== 0;
    },
  },
  methods: {
    updateValue() {
      this.$emit("update:value", this.$refs.input.value);
      this.clearErrors();
    },
    clearErrors() {
      this.$emit("update:errors", []);
    },
  },
};
</script>

<style scoped></style>
