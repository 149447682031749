export class Video {
    description = "";
    duration = 0;
    id = 0;
    path = "";
    thumbnail = ""
    title = ""
    constructor(video = null) {
        if (video != null) {
            this.description = video.description;
            this.duration = video.duration;
            this.id = video.id;
            this.path = video.path;
            this.thumbnail = video.thumbnail;
            this.title = video.title;
        }
    }
}
