import {Video} from "@/classes/Video";

export class Match {
    extra = "";
    extra_attention = "";
    goals = "";
    hair_color = "";
    pants_color = "";
    position = "";
    recognizable = "";
    shirt_color = "";
    shirt_number = "";
    shoe_brand = "";
    shoe_color = "";
    timestamps = [];
    video = Video;
    profile_image = undefined;

    constructor(match = null) {
        if (match === null) {
            return;
        }

        this.extra = match.extra;
        this.extra_attention = match.extra_attention;
        this.goals = match.goals;
        this.hair_color = match.hair_color;
        this.pants_color = match.pants_color;
        this.position = match.position;
        this.recognizable = match.recognizable;
        this.shirt_color = match.shirt_color;
        this.shirt_number = match.shirt_number;
        this.shoe_brand = match.shoe_brand;
        this.shoe_color = match.shoe_color;
        this.age = match.age;
        this.timestamps = match.timestamps ?? [];
        this.questions = match.questions;
        
        this.video = new Video(match.video)
    }

    addTimeStamp(timestamp) {
        this.timestamps.push(timestamp);
    }
}
