
export function stripHashtags(title) {
    // Titles are either absolute strings, or in the format `sport.####Tekst####`,
    // with the title between hashtags always being the title in Dutch.
    
    var idx = title.indexOf("####");
    if(idx !== -1) {
        title = title.substr(idx + 4);
        var lastIdx = title.indexOf("####");
        if(lastIdx !== -1) {
            title = title.substr(0, lastIdx);
        }
    }

    return title
}

export function choosePillColor(title) {
    title = stripHashtags(title);

    switch (title) {
        case "Algemeen":
        case "General":
            return "bg-white text-black"
        case "Verdedigen":
        case "Defending":
        case "Verdediging":
        case "Managen wedstrijd":
        case "Start":
        case "Verdedigend":
            return "bg-pill-light_blue text-black"
        case "Aanvallen":
        case "Attacking":
        case "Toepassen spelregels":
        case "Bochten":
        case "Aanvallend":
            return "bg-pill-green text-black"
        case "Verdedigen - Aanvallen":
        case "Defending - Attacking":
        case "Verdedigen -> Aanvallen":
        case "Defending -> Attacking":
        case "Passen":
        case "Volgen/positie kiezen":
        case "Basisprincipes":
        case "Springbulten":
        case "Speelplan":
        case "Volgende wedstrijd":
            return "bg-pill-yellow text-black"
        case "Aanvallen - Verdedigen":   
        case "Attacking - Defending":     
        case "Aanvallen -> Verdedigen":
        case "Attacking -> Defending":  
        case "Blokkeren":
        case "Communicatie":
        case "Rechte stukken":
        case "Tactiek":
        case "Voorbespreking":
            return "bg-pill-pink text-black"
        case "Serveren":
            return "bg-pill-red text-black"
        case "Setter":
            return "bg-pill-brown text-black"
        default:
            return "bg-white text-black"
    }
}