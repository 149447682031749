<template>
  <div class="text-white rounded">
    <div v-if="!newHighlight">
      <div class="flex text-white text-base font-medium">
        <h2 class="w-1/2 text-center px-3 py-4 rounded-t cursor-pointer unselectable" v-bind:class="{'bg-scorelitgray': !highlightsOpen}"
            v-on:click="highlightsOpen = false">
          <i18n-t keypath="review.title" />
        </h2>
        <h2 class="w-1/2 text-center px-3 py-4 rounded-t cursor-pointer unselectable" v-bind:class="{'bg-scorelitgray': highlightsOpen, 'text-scorelitgray': !needsHighlights}"
            v-on:click="openHighlightPanel">
          <i18n-t keypath="review.highlights.title" />
        </h2>
      </div>
      <div v-if="highlightsOpen" class="sidebar bg-scorelitgray rounded-b overflow-auto md:max-h-full">
        <div v-if="highlights.length > 0" class="p-4">
          <div v-for="(timestamp, index) in highlights" :key="timestamp.id">
            <div class="flex mt-4">
              <div class="w-1/12">
                <span
                    class="w-4 h-4 rounded-full bg-gradient-to-r from-gradient-orange to-gradient-red inline-block relative border border-white relative"
                    style="top: 7px"></span>
                <div v-if="index !== highlights.length - 1" class="h-full">
                  <span class=" inline-block h-full border border-white relative"
                        style="left: 7px; height: 108%"></span>
                </div>
              </div>
              <div class="w-11/12">
                <highlight :video-timestamp="timestamp" :review-i-d="review.id"></highlight>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex items-center justify-center h-64 text-center">
          <h3 class="px-6 text-gray-400">
            <i18n-t keypath="review.highlights.empty" />
          </h3>
        </div>
      </div>
      <div v-if="!highlightsOpen" class="sidebar bg-scorelitgray rounded-b overflow-auto md:max-h-full">
        <rapport :review="review"></rapport>
      </div>
    </div>
    <div v-else>
      <new-highlight @close="$emit('close')" 
        @timestamp="newTimeStamp" 
        :timestamp="timeStampVideo" 
        :subjects="subjects"
        :team="review?.team"
      ></new-highlight>
    </div>
  </div>
</template>

<script>
import NewHighlight from "@/components/review/NewHighlight";
import {VideoTimestamp} from "@/classes/VideoTimestamp";
import Highlight from "@/components/review/Highlight";
import Rapport from "@/components/review/Rapport";
import {setLoading} from "@/helper/store/loadingSpinnerHelper";
import messages from "@/helper/store/messageHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
  name: "ReviewSidebar",
  components: {Rapport, Highlight, NewHighlight},
  props: ['match', 'newHighlight', 'timeStampVideo', 'subjects', 'product', 'review'],
  watch: {
    newHighlight (isNewHighlight){
      if (isNewHighlight){
        this.highlightsOpen = true;
      }
    }
  },
  data() {
    return {
      highlightsOpen: false,
    }
  },
  computed: {
    needsHighlights: function (){
      return this.product.report === 1;
    },
    highlights: function () {
      if (!this.match) {
        return [];
      }

      const parseTimestampSeconds = (timestamp) => {
        const hours = parseInt(timestamp.substr(0, 2));
        const minutes = parseInt(timestamp.substr(3, 2));
        const seconds = parseInt(timestamp.substr(6, 2));
        return (hours * 3600) + (minutes * 60) + seconds;
      };
      
      const byTimestampDesc = (a, b) => {
        return parseTimestampSeconds(b.timestamp) - parseTimestampSeconds(a.timestamp)
      };

      const highlights = this.match.timestamps;

      return highlights.sort(byTimestampDesc);
    }
  },
  methods: {
    newTimeStamp(timestamp) {
      setLoading(true);
      timestamp.then((data) => {
        this.$store.commit("cancelDrawing");

        let timestamp = new VideoTimestamp(data);
        this.$emit("timestamp", timestamp);
        this.$store.commit("closeHighlight");
      })
      .finally(() => setLoading(false));
    },
    openHighlightPanel () {
      if (!this.needsHighlights){
        messages.warn($t('review.highlights.notInProduct'))
        return;
      }
      this.highlightsOpen = true
    }
  },
  created() {
  }
}
</script>

<style scoped>
@media (min-width: 1024px) {
    .sidebar {
        height: calc(100vh - 260px);
    }
}
</style>
