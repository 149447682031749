<template>
  <div class="bg-black rounded h-10 py-2 px-4 flex items-center justify-between" v-bind:class="prop === 'large'? 'min-w-full min-h-full flex-col' : 'flex-row' ">
    <p class="text-button-grey text-sm inline-block" v-bind:class="prop === 'large'? 'w-full' : '' ">{{ type }}</p>
    <p class="text-white text-sm font-medium float-right" v-bind:class="prop === 'large'? 'min-w-full min-h-full': '' ">{{ value }}</p>
  </div>
</template>

<script>
export default {
  name: "SearchProperty",
  props: ['type', 'value', 'prop'],
}
</script>

<style scoped>

</style>
