<template>
  <teleport to="body">
    <div
      v-if="isOpen"
      class="fixed z-50 inset-0 overflow-y-auto"
      v-on:dragenter="handleDragEnter"
      v-on:dragleave="handleDragLeave"
      v-on:dragover="handleDragOver"
      v-on:drop="handleOnDrop"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          v-if="isOpen"
          @click="onDismiss"
          class="fixed inset-0 transition-opacity"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;

        <div
          v-if="isOpen"
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <slot :isDragIncoming="isDragIncoming" />
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    noLightDismiss: Boolean,
    fileUploadContainer: Boolean,
  },
  emits: ["update:isOpen"],
  data() {
    return {
      isDragIncoming: false,
    };
  },
  methods: {
    onDismiss() {
      if (!this.noLightDismiss) {
        this.$emit("update:isOpen", false);
      }
    },

    handleDragEnter(e) {
      if (!this.fileUploadContainer) return;
      e.preventDefault();
      this.isDragIncoming = true;
    },
    handleDragLeave(e) {
      if (!this.fileUploadContainer) return;
      e.preventDefault();
      this.isDragIncoming = false;
    },
    handleDragOver(e) {
      if (!this.fileUploadContainer) return;
      e.preventDefault();
      this.isDragIncoming = true;
    },
    handleOnDrop(e) {
      if (!this.fileUploadContainer) return;
      e.preventDefault();
      this.isDragIncoming = false;
    },
  },
};
</script>
