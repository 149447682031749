<template>
  <div class="mt-2">
    <div v-if="!rapportOpen && subjects !== null" class="p-4 lg:p-4 xl:p-10">
      <div class="rounded bg-black border border-border-gray mt-2 overflow-hidden">
        <div v-for="subject in subjects" :key="subject">
            <div
                class="border-b border-border-gray cursor-pointer group hover:bg-scorelitgray transition duration-200 "
                v-on:click="rapportOpenQuestions(subject)">
              <div class="flex justify-between">
                <div class="flex">
                    <span class="w-1 h-full mr-4" v-bind:class="
                            chooseColorPill(
                                subject.title
                            )
                        ">
                    </span>
                  <p class="py-4 text-gray-300 group-hover:text-white unselectable">
                    {{ subject.title }}
                  </p>
                </div>
                <div class="flex items-center">
                  <i class="fal fa-check-circle text-gradient mr-4" v-if="subject.subject_done"></i>
                  <i v-else class="far fa-chevron-right relative text-gray-300 text-xs mr-4 group-hover:text-white"></i>
                </div>
              </div>
            </div>
        </div>

        <div class="pr-4 pl-5 cursor-pointer hover:bg-scorelitgray transition duration-200 group"
             v-on:click="rapportOpenVideoMessage">
          <div class="flex items-center justify-between">
            <p class="py-4 text-gray-300 group-hover:text-white unselectable">
              <i18n-t keypath="review.message.title" />
            </p>
            <div class="flex items-center">
              <i class="fal fa-check-circle text-gradient" v-if="$store.state.rapport.video"></i>
              <i v-else class="far fa-chevron-right relative text-gray-300 text-xs group-hover:text-white"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="rapportOpen" class="p-4 lg:p-4 xl:p-10">
      <div class="flex justify-between items-center">
        <div
            class="flex justify-center items-center bg-white hover:bg-gray-200 rounded-full h-8 w-8 cursor-pointer"
            v-on:click="closeRapport">
          <i class="fal fa-angle-left text-xl text-black"></i>
        </div>
        <button v-if="!videoMessage"
                class="flex items-center ml-6 rounded-lg py-1 px-4 text-black text-xs focus:outline-none active:outline-none cursor-default"
                v-bind:class="chooseColorPill(subject.title)">
          <i class="w-2 h-2 rounded-full inline-block mr-3 bg-black"></i>
          <p class="font-medium">{{ subject.title }}</p>
        </button>
        <button v-else
                class="flex items-center ml-6 rounded-lg py-1 px-4 text-black text-xs"
                v-bind:class="chooseColorPill('default')">
          <i class="w-2 h-2 rounded-full inline-block mr-3 bg-black"></i>
          <p class="font-medium">
            <i18n-t keypath="review.message.title" />
          </p>
        </button>
      </div>

      <video-message v-if="videoMessage"></video-message>
      <specific-rapport v-else :questions="questions"></specific-rapport>
      <div class="mt-4" v-if="showUpdateButton">
        <button v-on:click="updateReview" class="button w-full">
          <i18n-t keypath="review.done" />
        </button>
      </div>
    </div>
    <div v-if="subjects == null && !rapportOpen" >
      <div class="rounded bg-black border border-border-gray overflow-hidden m-8">
        <div class="pr-4 pl-5 cursor-pointer hover:bg-scorelitgray transition duration-200 group"
             v-on:click="rapportOpenVideoMessage">
          <div class="flex items-center justify-between">
            <p class="py-4 text-gray-300 group-hover:text-white">
              <i18n-t keypath="review.message.title" />
            </p>
            <div class="flex items-center">
              <i class="fal fa-check-circle text-gradient" v-if="$store.state.rapport.video"></i>
              <i v-else class="far fa-chevron-right relative text-gray-300 text-xs group-hover:text-white"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showSaveButton" class="m-8">
      <div class="mt-4">
        <button class="button w-full" v-on:click="openConfirmModal">
          <i18n-t keypath="review.send.button" />
        </button>
      </div>
    </div>
    <send-rapport-confirm v-model:show="showConfirmModal"></send-rapport-confirm>
  </div>
</template>

<script>
import {choosePillColor} from "@/helper/video/ColorHelper";
import SpecificRapport from "@/components/review/rapport/SpecificRapport";
import VideoMessage from "@/components/review/message/VideoMessage";
import { updateReview } from "@/helper/api/ReviewHelper";
import messages from "@/helper/store/messageHelper";
import SendRapportConfirm from "@/components/review/rapport/SendRapportConfirm";
import { status } from "@/helper/models/Review";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
  name: "Rapport",
  components: {SendRapportConfirm, VideoMessage, SpecificRapport},
  props: {
    review: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      rapportOpen: false,
      questions: undefined,
      subjects: this.$store.state.rapport.subjects,
      subject: undefined,
      videoMessage: false,
      showConfirmModal: false
    };
  },
  computed: {
    rapportDone () {
      return this.$store.state.rapport.reviewDone;
    },
    raportHasVideo() {
      return !!this.$store.state.rapport.video;
    },
    raportSubmitted() {
      return status(this.review) === "finished";
    },
    showSaveButton() {
      return !this.rapportOpen && this.rapportDone && this.raportHasVideo && !this.raportSubmitted;
    },
    showUpdateButton() {
      return !this.raportSubmitted;
    }
  },
  methods: {
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    updateReview() {
      const review_id = this.$route.params.id;
      updateReview(review_id)
        .then(() => {
          messages.success($t('review.saved'));
          this.closeRapport();
        })
        .catch(err => {
          console.log(err);
        });
    },
    closeRapport() {
      this.rapportOpen = false;
      this.videoMessage = false;
    },
    chooseColorPill(title) {
      return choosePillColor(title);
    },
    rapportOpenVideoMessage() {
      this.videoMessage = true;
      this.rapportOpen = true;
    },
    rapportOpenQuestions(subject) {
      this.rapportOpen = true;
      this.questions = subject.questions;
      this.subject = subject;
    },
  },
};
</script>

<style scoped>
.fa-check-circle {
  font-size: 1.3em;
}
</style>
