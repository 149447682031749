<template>
  <div>
    <div v-for="subject in questions" :key="subject.title">
      <span class="w-full h-px inline-block bg-button-grey"></span>
      <div class="py-4">
        <p class="font-bold text-lg mb-2">{{ subject.title }}</p>
        <p class="font-bold text-base" v-if="subject.description">{{ subject.description }}</p>
        <div v-for="question in subject.options" :key="question.title">
          <checkbox-rapport :name="question.title" :value="question.checked" v-if="subject.type === 'checkbox'"></checkbox-rapport>
          <question-slide :name="question.title" :value="question.value" :min-value="question.minimumValue" v-else></question-slide>
        </div>
        <conclusion-rapport :subject="subject" :value="subject.conclusion" v-if="subject.type === 'checkbox'"></conclusion-rapport>
      </div>

    </div>
  </div>
</template>

<script>
import QuestionSlide from "@/components/review/rapport/QuestionSlide";
import CheckboxRapport from "@/components/review/rapport/CheckboxRapport";
import ConclusionRapport from "@/components/review/rapport/ConclusionRapport";
export default {
  name: "SpecificRapport",
  props: ["questions"],
  components: {ConclusionRapport, CheckboxRapport, QuestionSlide},
  data() {
    return {}
  },
  created() {
  }
}
</script>

<style scoped>

</style>
