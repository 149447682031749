<template>
  <div>
    <div class="py-4">
      <span class="w-full h-px inline-block bg-button-grey"></span>
      <div class="py-4">
        <p class="font-bold text-lg mb-2">
          <i18n-t keypath="review.message.title" />
        </p>
        <p class="font-normal text-sm mb-6 text-gray-200">
          <i18n-t keypath="review.message.description" />
        </p>
        
        

        <div class="flex flex-col justify-between items-center">
          <div v-if="isSafari" class="mb-6">
            <div class="rounded-md border border-yellow-800 bg-yellow-900 p-4 mb-10">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-yellow-400">
                    <i18n-t keypath="review.errors.safari.title" />
                  </h3>
                  <div class="mt-2 text-sm text-yellow-500">
                    <p>
                      <i18n-t keypath="review.errors.safari.description" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex w-full flex-col justify-between items-center ">
            <button type="button" v-on:click="openCloseVideo" class="button-green w-full mb-4" v-if="$store.state.rapport.video">
              <i class="fa fa-eye mr-3"></i>
              <i18n-t keypath="review.message.view" />
            </button>           
            <button type="button" v-on:click="openCloseRecording" class="text-center button-transparent w-full">
              <i class="fa fa-video-plus mr-3"></i>
              <i18n-t keypath="review.message.record.replace" />
            </button>
            <span class="text-sm py-3 font-medium">of</span>
          </div>
          
          <label v-on:click="uploadVideo"
                 v-if="!video"
                 class="text-center button-transparent w-full">
            <i class="fa fa-file-upload mr-3"></i>
            <i18n-t keypath="review.message.upload" />
            <input type="file" class="invisible hidden" @change="uploadVideo">
          </label>
          <label v-on:click="uploadVideo"
                  v-else
                  class="text-center button-transparent w-full">
            <i class="fa fa-file-upload mr-3"></i>
            <i18n-t keypath="review.message.replaceUpload" />
            <input type="file" class="invisible hidden" @change="uploadVideo">
          </label>
        </div>
        <span v-if="wrongFileFormat" class="text-xs text-scorelitred font-medium">
          <i18n-t keypath="review.errors.fileFormat" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {setLoading} from "@/helper/store/loadingSpinnerHelper";
import messages from "@/helper/store/messageHelper";
import { saveOrUpdateVideo } from "@/helper/api/ReviewVideoHelper";
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
  name: "VideoMessage",
  data() {
    return {
      videoChosen: undefined,
      video: this.$store.state.rapport.video,
      wrongFileFormat: false,
      isSafari: false
    }
  },
  mounted() {
    this.isSafari = this.safariCheck();
  },
  methods: {
    uploadVideo(event) {
      this.wrongFileFormat = false;
      const files = event.target.files;

      if (files === undefined || files.length === 0) {
        return ;
      }

      let file_type = files[0].type;
      if (!file_type.startsWith('video/')) {
        this.wrongFileFormat = true;
        return ;
      }

      const data = {
        video: files[0],
        title: 'test',
        description: 'test2',
      };

      setLoading(true);
      const review_id = this.$route.params.id;
      const video_id = this.video ? this.video.id : undefined;
      saveOrUpdateVideo(review_id, video_id, data)
        .then(() => {
          messages.success($t('review.message.success'));
        })
        .catch(err => {
          messages.error(err.message);
          console.log(err);
        })
        .finally(()=> {
          setLoading(false);
        });
    },
    openCloseVideo() {
      this.$store.commit("setVideoShow", true);
    },
    openCloseRecording() {
      this.$store.commit("setVideoShow", false);
    },
    safariCheck () {
      // hack: first check for the other two browsers, to prevent false positives.
      const isOpera = navigator.userAgent.indexOf("Opera") !== -1 || navigator.userAgent.indexOf('OPR') !== -1;
      const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;

      return !isOpera && !isChrome && navigator.userAgent.indexOf("Safari") != -1;
    }
  }
}
</script>

<style scoped>

</style>
