<template>
    <div class="flex flex-col min-h-screen bg-black">
        <Header 
          :match="match" 
          :title="getTitle()" 
          :trainee="trainee"
          :team="team" 
          :review="review"
        ></Header>
      <div class="flex-grow mb-auto px-8 pt-10" v-if="review">
        <video-recording @modal-closed="refreshVideoRecorder" :key="videoRecorderRefreshKey" />
        <div class="flex flex-col md:flex-col lg:flex-row">
                <div class="w-full sm:w-full md:w-full lg:w-2/3 mr-0 md:mr-0 lg:mr-6 mb-8 md:mb-8 lg:mb-0">
                    <Video 
                      :video-url="match.video.path" 
                      :thumbnail="match.video.thumbnail" 
                      @highlight="newHighlightCreation($event)" 
                      :review-id="review.id" 
                      :display-add-highlight-button="displayAddHighlightButton"
                    />
                </div>
                <div class="w-full sm:w-full md:w-full lg:w-1/3 mb-8" v-if="match">
                    <review-sidebar 
                      :review="review"
                      @close="closeNewTimeStamp" 
                      @timestamp="newTimeStamp" 
                      :match="match" 
                      :new-highlight="newHighlight" 
                      :timestampVideo="timeStampVideo" 
                      :subjects="subjects" 
                      :product="product">
                    </review-sidebar>
                </div>
            </div>
        </div>
        <footer>
            <Footer />
        </footer>
    </div>
</template>

<script>
import Header from "@/components/review/Header";
import Footer from "@/components/dashboard/Footer";
import { getReview } from "@/helper/api/ReviewHelper";
import ReviewSidebar from "@/components/review/ReviewSidebar";
import {Match} from "@/classes/Match";
import {VideoTimestamp} from "@/classes/VideoTimestamp";
import Video from "@/components/review/Video";
import VideoRecording from "@/components/review/message/VideoRecording";
import { updateReview } from "@/helper/api/ReviewHelper";
import { status } from "@/helper/models/Review";
import { isGeneralSubject } from "@/store/rapportStore"

export default {
  name: "Review",
  components: {VideoRecording, Video, ReviewSidebar, Header, Footer},
  data() {
    return {
      review: undefined,
      match: undefined,
      subjects: [],
      newHighlight: false,
      timeStampVideo: "00:00:00.000",
      product: {},
      videoRecorderRefreshKey: 1,
    }
  },
  computed: {
    raportSubmitted() {
      return status(this.review) === "finished";
    },
    displayAddHighlightButton () {
      return this.product?.report && !this.raportSubmitted
    },
    trainee() {
      return this.review?.trainee
    },
    team() {
      return this.review?.team
    }
  },
  created() {
    this.getReviewItem();
  },
  watch: {
    '$store.state.highlight.removeDrawing': function () {
      this.closeNewTimeStamp();
    }
  },
  methods: {
    // HACK: to reset video recorder state. 
    refreshVideoRecorder() {
      this.videoRecorderRefreshKey++;
    },
    getTitle() {
      if (!this.match) {
        return ''
      }
      const age = this.review.match.age ?? ''
      return `${this.match.video.title} ${age}`
    },
    newHighlightCreation(time) {
      this.timeStampVideo = time;
      this.newHighlight = true

      // save review when new highlight is created
      this.updateReview()
    },
    updateReview() {
      const review_id = this.$route.params.id;
      updateReview(review_id)
        .then(() => {
        })
        .catch(err => {
          console.log(err);
        });
    },
    closeNewTimeStamp() {
      this.newHighlight = false;
      this.getReviewItem();
      this.$store.commit("closeHighlight");
    },
    newTimeStamp(timestamp) {
      this.newHighlight = false;
      this.match.timestamps.push(timestamp);
    },
    getReviewItem() {
      getReview(this.$route.params.id)
        .then(review => {
          // console.log(review);
          this.review = review;
          this.match = new Match(review.match);
          this.match.timestamps = review.timestamps.map(timestamp => new VideoTimestamp(timestamp));
          try {
            this.subjects = review.questions
              .filter(subject => !isGeneralSubject(subject))
              .map(category => {
                return {
                  label: category.title,
                  value: category.titleKey || category.title
                };
              });
          } catch (error) {
            console.error(error);
            this.subjects = [];
          }
          this.product = review.product;
        })
        .catch(err => {
          console.log(err);
        });
    },
  }
}
</script>

<style scoped>

</style>
