import axios from "axios"

export function createStripeAccount(form) {
    const url = "v1/coach/stripe";

    return axios.post(url, form).then(response => response.data);
}

export function getStripeAccount() {
    const url = "v1/coach/stripe";

    return axios.get(url).then(response => response.data.stripe_account ?? null);
}