<template>
    <div>
        <div class="transition-opacity duration-300 w-full bg-black_half z-10 absolute" id="trainee_sub_header_shadow"
             v-bind:class="open? 'opacity-100 visible h-screen' : 'opacity-0 invisible h-0'">
        </div>

        <div class="w-full bg-scorelitgray pt-10 pb-10 transition-all duration-500 border-t border-b border-gray-600 absolute z-10"
             v-bind:style="open? 'top:'+ headerHeight +'px' : 'top:-600px'" v-if="match"
             v-bind:class="open? 'opacity-100 ' : 'opacity-0'"
        >
            <div class="mx-auto max-w-5xl px-4 lg:px-0">
                <div class="flex flex-auto border-b border-gray-600 pb-4">
                    <div class="flex-1 flex flex-col items-center justify-between">
                        <a v-if="trainee.profile_image" :href="trainee.profile_image" target="_blank" class="shape flex border-4 rounded-full justify-items-center items-center mb-4">
                            <img :src="trainee.profile_image" class="transform w-32 h-32 rounded-full object-cover">
                        </a>
                        <div v-else class="rounded-full w-32 h-32 bg-white flex justify-items-center items-center mb-4">
                            <i class="fal fa-user-alt rounded-full p-6 relative user"></i>
                        </div>
                        <div class="bg-black rounded h-10 py-2 px-4 flex items-center justify-between flex-row">
                            <p class="text-white text-sm font-medium float-right">{{  trainee.name }}</p>
                        </div>
                    </div>
                    <div class="flex-2">
                        <search-property class="w-full inline-block" :type="$t('review.trainee.extra_attention')"
                         :value="review.extra_attention"
                         prop="large"></search-property>
                    </div>
                </div>
                <div class="flex mt-4">
                    <div class="w-2/3 sm:grid sm:gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 justify-items-center">
                        <search-property class="w-full inline-block mb-4 sm:mb-0" :type="$t('review.trainee.position')" :value="match.position" />
                        <search-property class="w-1/2 inline-block w-full" :type="$t('review.trainee.age')" :value="match.age"></search-property>

                        <template v-for="(answer, key) in shortAttributes" :key="key"> 
                            <search-property 
                                :type="translateKey(key)" 
                                :value="answer"
                                class="w-full inline-block mb-4 sm:mb-0"    
                            />
                        </template>
                    </div>
                    <div class="mt-0 ml-3 w-1/3">
                        <template v-for="(answer, key) in longAttributes" :key="key">
                            <search-property class="w-full lg:w-2/3 inline-block" :type="translateKey(key)" :value="answer" prop="large" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SearchProperty from "@/components/SearchProperty";
import { getMatchQuestions } from "@/helper/api/MatchQuestionHelper"

export default {
  name: "TraineeSubHeader",
  components: {SearchProperty},
  props: ['match', 'open', 'trainee', 'review'],
  data() {
    return {
      match_questions: [],
      headerHeight: 0
    }
  },
  computed: {
      shortAttributes() {
            if (!this.match?.questions) {
                return {}
            }
            const keysOfLongAttrs = this.match_questions
                        .filter(question => question.type === "text")
                        .map(question => question.key);

            
            const attributes = Object.assign({}, this.match.questions);

            keysOfLongAttrs.forEach(key => {
                delete attributes[key]
            })

            return attributes
        },
        longAttributes() {
            if (!this.match?.questions) {
                return {}
            }
            const keysOfShortAttrs = this.match_questions
                        .filter(question => question.type !== "text")
                        .map(question => question.key);

            
            const attributes = Object.assign({}, this.match.questions);

            keysOfShortAttrs.forEach(key => {
                delete attributes[key]
            })

            return attributes
        }
  },
  created() {
      console.log(this.match)
      this.getDynamicQuestions();
  },
  mounted() {
    let header = document.getElementById("review-header")
    this.headerHeight = header.clientHeight
  },
  methods: {
    async getDynamicQuestions() {
      this.match_questions = await getMatchQuestions();
    },
    translateKey(key) {
      return this.match_questions.find(question => question.key === key)?.question ?? ""
    },
  }
}
</script>

<style scoped>
.user {
  display: inline-block;
  margin: 0 auto;
  font-size: 30px;
}
</style>
